import chevron from '@a/icons/chevron.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Dispatch, SetStateAction } from 'react';
import { PrimaryBtn } from './buttons';

export const Form = styled.form<{ submit: boolean }>`
    position: relative;
    width: 100%;
    max-width: 800px;
    scroll-margin-top: 180px;
    background-color: ${({ theme }) => theme.colors.gray6};

    > h3 {
        margin: 0 0 16px;
        text-transform: capitalize;
    }

    > button:last-of-type {
        margin: 24px auto 0;
        display: flex;
        width: 100%;
    }

    ${({ submit }) =>
        submit &&
        css`
            ::before {
                opacity: 0;
            }
            * {
                pointer-events: none;
                visibility: hidden;
            }
        `};
`;

export const Input = styled.input<{ rows?: number; setColor?: boolean; select?: boolean }>`
    color: ${({ theme, setColor }) => (setColor ? theme.colors.gray3 : theme.colors.gray2)};
    border: 1.5px solid transparent;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    width: 100%;
    padding: 11px 16px;
    display: block;
    outline: none;
    transition:
        border-color 0.3s ease-in,
        opacity 0.3s ease-in-out;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 150%;
    resize: none;

    &[type='file'] {
        padding: 8px 16px;
    }

    :hover {
        border-color: ${({ theme }) => theme.colors.blue3};
    }

    :focus-visible {
        border-color: ${({ theme }) => theme.colors.blue4};
    }

    :focus-within {
        color: ${({ theme }) => theme.colors.gray2};
    }

    ::placeholder {
        color: ${({ theme }) => theme.colors.gray4};
    }

    :disabled {
        pointer-events: none;
        opacity: 0.7;
    }

    option {
        text-transform: capitalize;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        text-fill-color: ${({ theme }) => theme.colors.gray2};
        color: ${({ theme }) => theme.colors.gray2};
        -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.05) inset;
        transition: background-color 50000s ease-in-out 0s;
    }

    :not(:placeholder-shown):invalid {
        border-color: #d21717;
    }

    ${({ setColor, theme }) =>
        setColor &&
        css`
            text-transform: uppercase;
            padding: 13px 16px;

            :not(:placeholder-shown):invalid {
                border-color: ${theme.colors.gray3};
            }

            :focus-within:invalid {
                border-color: #d21717;
            }
        `};

    ${({ select }) =>
        select &&
        css`
            background-image: url(${chevron});
            background-position: center right 20px;
            background-repeat: no-repeat;
            appearance: none;

            &::-ms-expand {
                display: none;
            }
        `};
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 10px;

    > span {
        color: ${({ theme }) => theme.colors.gray1};
        font-weight: 400;
        margin-bottom: 10px;
        transition: color 0.3s ease-in-out;
        display: block;

        > strong {
            color: #d21717;
            margin-left: 0.5ch;
        }
    }

    :hover {
        > span {
            color: ${({ theme }) => theme.colors.blue3};
        }
    }

    :focus-within {
        > span {
            color: ${({ theme }) => theme.colors.blue4};
        }
    }
`;

const StyledMsg = styled.div<{ submit: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    background-color: ${({ theme }) => theme.colors.gray6};
    color: ${({ theme }) => theme.colors.gray1};

    &,
    > h3,
    > p,
    > button {
        visibility: ${({ submit }) => (submit ? 'visible' : 'hidden')} !important;
        pointer-events: ${({ submit }) => (submit ? 'all' : 'none')} !important;
    }

    > h3 {
        margin: 0;
    }

    > p {
        font-size: 1.25rem;
        margin: 2rem 0 0;
    }

    @media (min-width: 1024px) {
        padding: 40px;
    }
`;

export const Thanks = ({
    submit,
    setSubmit,
}: {
    submit: boolean;
    setSubmit: Dispatch<SetStateAction<'init' | 'submitting' | 'submitted' | 'error'>>;
}) => (
    <StyledMsg submit={submit}>
        <h3>Thank you</h3>
        <p>Someone will be right with you shortly!</p>

        <PrimaryBtn onClick={() => setSubmit('init')} aria-hidden={!submit} white>
            Resubmit Form
        </PrimaryBtn>
    </StyledMsg>
);

export const Error = ({ error }: { error: boolean }) => (
    <StyledMsg submit={error}>
        <h3>Something went wrong</h3>
        <p>Please give us a call instead.</p>
    </StyledMsg>
);
